import { MonetizationOn } from '@mui/icons-material'
import { Alert, Grid } from '@mui/material'

export const LowBalanceWarning = ({ isFetching, data }) => {
  // convert data array to object
  const wallet = data.reduce((acc, item) => {
    acc[item.name] = item.values

    return acc
  }, {})

  return (
    <>
      {!isFetching && (
        <Grid container justifyContent="flex-start" columns={{ sm: 1, md: 1, lg: 2 }}>
          {wallet.BINANCE_SPOT?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_binance_spot_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance <strong>USDT</strong> balance is low: ({wallet.BINANCE_SPOT.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.BINANCE_SPOT?.AVAX?.balance < 2 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_BINANCE_SPOT_AVAX"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance <strong>AVAX</strong> balance is low: ({wallet.BINANCE_SPOT.AVAX.balance} AVAX)
              </Alert>
            </Grid>
          )}
          {wallet.BINANCE_SPOT?.BNB?.balance < 0.6 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_BINANCE_SPOT_BNB"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance <strong>BNB</strong> balance is low: ({wallet.BINANCE_SPOT.BNB.balance} BNB)
              </Alert>
            </Grid>
          )}
          {wallet.BINANCE_SPOT?.POL?.balance < 300 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_BINANCE_SPOT_POL"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance <strong>POL</strong> balance is low: ({wallet.BINANCE_SPOT.POL.balance} POL)
              </Alert>
            </Grid>
          )}
          {wallet.BINANCE_SPOT?.SOL?.balance < 2 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_BINANCE_SPOT_SOL"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance <strong>SOL</strong> balance is low: ({wallet.BINANCE_SPOT.SOL.balance} SOL)
              </Alert>
            </Grid>
          )}
          {wallet.BINANCE_SPOT?.ETH?.balance < 2 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_BINANCE_SPOT_ETH"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance <strong>ETH</strong> balance is low: ({wallet.BINANCE_SPOT.ETH.balance} ETH)
              </Alert>
            </Grid>
          )}
          {wallet.GATE_SPOT?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_GATE_SPOT_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Gate <strong>USDT</strong> balance is low: ({wallet.GATE_SPOT.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.GATE_SPOT_PILOT?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_GATE_SPOT_PILOT_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Gate_pilot <strong>USDT</strong> balance is low: ({wallet.GATE_SPOT_PILOT.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.KUCOIN_SPOT?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_KUCOIN_SPOT_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Kucoin <strong>USDT</strong> balance is low: ({wallet.KUCOIN_SPOT.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.MEXC_SPOT?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_MEXC_SPOT_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Mexc <strong>USDT</strong> balance is low: ({wallet.MEXC_SPOT.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.base?.ETH?.balance < 0.2 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_base_ETH"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Base <strong>ETH</strong> balance is low: ({wallet.base.ETH.balance} ETH)
              </Alert>
            </Grid>
          )}
          {wallet.base?.USDC?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_base_USDC"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Base <strong>USDC</strong> balance is low: ({wallet.base.USDC.balance} USDC)
              </Alert>
            </Grid>
          )}
          {wallet.binanceSmartChain?.BNB?.balance < 0.6 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_binanceSmartChain_BNB"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance Smart Chain <strong>BNB</strong> balance is low: ({wallet.binanceSmartChain.BNB.balance} BNB)
              </Alert>
            </Grid>
          )}
          {wallet.binanceSmartChain?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_binanceSmartChain_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance Smart Chain <strong>USDT</strong> balance is low: ({wallet.binanceSmartChain.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.binanceSmartChain?.USDC?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_binanceSmartChain_USDC"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Binance Smart Chain <strong>USDC</strong> balance is low: ({wallet.binanceSmartChain.USDC.balance} USDC)
              </Alert>
            </Grid>
          )}
          {wallet.blast?.ETH?.balance < 0.2 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_blast_ETH"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Blast <strong>ETH</strong> balance is low: ({wallet.blast.ETH.balance} ETH)
              </Alert>
            </Grid>
          )}
          {wallet.polygon?.USDC?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_polygon_USDC"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Polygon <strong>USDC</strong> balance is low: ({wallet.polygon.USDC.balance} USDC)
              </Alert>
            </Grid>
          )}
          {wallet.polygon?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_polygon_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Polygon <strong>USDT</strong> balance is low: ({wallet.polygon.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
          {wallet.polygon?.POL?.balance < 300 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_polygon_POL"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Polygon <strong>POL</strong> balance is low: ({wallet.polygon.POL.balance} POL)
              </Alert>
            </Grid>
          )}
          {wallet.solana?.USDC?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_solana_USDC"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Solana <strong>USDC</strong> balance is low: ({wallet.solana.USDC.balance} USDC)
              </Alert>
            </Grid>
          )}
          {wallet.solana?.SOL?.balance < 2 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_solana_SOL"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Solana <strong>SOL</strong> balance is low: ({wallet.solana.SOL.balance} SOL)
              </Alert>
            </Grid>
          )}
          {wallet.ton?.USDT?.balance < 1000 && (
            <Grid item sm={1} md={1} lg={1} key={"wallet_ton_USDT"}>
              <Alert style={{ width: '100%' }} icon={<MonetizationOn fontSize='inherit' />} severity='warning'>
                Ton <strong>USDT</strong> balance is low: ({wallet.ton.USDT.balance} USDT)
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}
