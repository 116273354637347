import cx from "classnames";
import botAPI from "../../../../api/bot-api";
import { useEffect, useState } from "react";
import s from './AnnouncementBlock.module.css'

const Announcement = ({ announcement }) => {
  const { published_at, title, url } = announcement;

  const date = new Date(published_at).toLocaleDateString("en-CA", {
    year:"numeric",
    month:"2-digit",
    day:"2-digit",
    hour:"2-digit",
    minute:"numeric",
    hour12:false });

  return <div className={s.announcement}>
    <a className={s.announcementLink} href={url} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
    <span className={s.announcementTime}>
      {date}
    </span>
  </div>
};

const AnnouncementBlock =  ()=> {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    ;(async () => {
      const res = await botAPI.getAnnouncements()

      setAnnouncements(res)
    })()
  }, [])

  if (!announcements.length) {
    return <div className={cx(s.announcementsEmpty, s.announcements)}>
      There are no announcements yet.
    </div>
  }

  return <div className={cx(s.announcements)} id="announcements_block">
    <span className={s.announcementsTitle}>Announcements</span>
    {announcements.map((announcement, index) => (<Announcement announcement={announcement} key={index}/>))}
  </div>;
}

export default AnnouncementBlock
