import { Paper, Typography, Select, MenuItem } from '@mui/material'
import Button from 'components/Button/Button'
import Table from 'components/Table/Table.jsx'
import { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { BotEdit } from './BotEdit'
import { useBotEditApi, useBotGraph, PAGE_SIZES, useBotBulkActions } from './hooks'
import { botErrorSort } from 'utilities/sorting'
import { getColumns } from './columns'
import { useBotBalanceLevelingApi } from './BotEditor/BalanceLeveling/hooks'
import s from './BotTable.module.css'
import { SelectAllActions } from './SelectAll'
import { BlockedBots } from './BlockedBots/BlockedBots'

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'

export const BotTable = ({
  botsType = UNARCHIVED,
  data,
  tableRef,
  botsQuery,
  botsQueryKey,
  pagination,
  exchanges,
  isFetching,
  setIsFetching,
  filterBots,
  skipColumns = [],
  isFullTable = true,
}) => {
  const { selectBot, selectedAll, selectAllBots, selectedBots, archiveSelected, enableSelected, disableSelected } =
    useBotBulkActions({ data, botsQueryKey })

  const columns = useMemo(() => getColumns(botsType, undefined, undefined, skipColumns), [botsType, skipColumns])
  const navigate = useNavigate()
  const { enableRule, disableRule } = useBotBalanceLevelingApi()

  const [editedBot, botArchiveApi, botStatusApi, botPopup, , cacheBot] = useBotEditApi({
    data,
    botsType,
    botsQuery,
    botsQueryKey,
  })
  const [graph, openGraph, closeGraph] = useBotGraph()

  useEffect(() => {
    if (cacheBot.enabled) {
      cacheBot.clearBotsStatuses()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.pageSize])

  const tableDisabled = isFetching || botStatusApi.editing

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2} id='bot_details_table'>
      <div className={s.heading}>
        <div className={s.headingTitle}>
          <Typography className={s.headingTitleText} variant={'h5'}>
            {botsType === ARCHIVED ? 'Archived bots' : 'Bot List'}
          </Typography>
        </div>
        {botsType === UNARCHIVED && (
          <div className='flex-row' style={{ gap: 12 }}>
            <button
              className={s.createBot}
              disabled={tableDisabled}
              onClick={() => navigate('/archived-bots')}
              id='archived_bots_link'
            >
              <i className='fas fa-archive' /> Archived bots
            </button>
            <button
              className={s.createBot}
              disabled={tableDisabled}
              onClick={botStatusApi.openBotEditor}
              id='create_bot_icon'
            >
              <i className='fas fa-plus' /> Create a bot
            </button>
          </div>
        )}
      </div>
      {botsType === UNARCHIVED && (
        <SelectAllActions
          selectedCount={selectedBots.length}
          archiveSelected={archiveSelected}
          enableSelected={enableSelected}
          disableSelected={disableSelected}
        />
      )}
      {isFullTable && <BlockedBots></BlockedBots>}
      <Table
        data={data}
        tableRef={tableRef}
        columns={columns}
        loading={tableDisabled}
        hiddenColumns={botsType === ARCHIVED ? ['opportunity', 'dexPrice', 'dexTotal'] : []}
        tableStyle={{ height: isFullTable ? 'calc(100vh - 300px)' : '170px', margin: 0, minHeight: 300 }}
        initialState={{
          sortBy: [{ id: 'id', desc: true }],
        }}
        sortFns={{ botErrorSort }}
        meta={{ selectAllBots, selectedAll }}
        cellProps={{
          botsType,
          botsQuery,
          botsQueryKey,
          openConfirmArchivation: botArchiveApi.openConfirmArchivation,
          openGraph,
          isStatusChanging: botStatusApi.editing,
          openBotEditor: botStatusApi.openBotEditor,
          switchBotStatus: botStatusApi.switchBotStatus,
          changeBotArchive: botArchiveApi.changeBotArchive,
          enableBotRule: enableRule,
          disableBotRule: disableRule,
          selectBot,
          selectedBots,
        }}
      />
      {isFullTable && (
        <div className={s.pagination}>
          <Button onClick={pagination.decrementPage} disabled={isFetching || pagination.page === 1} id='prev_page'>
            Prev
          </Button>
          <div>{pagination.page}</div>
          <div>
            <Select value={pagination.pageSize} onChange={pagination.changePageSize}>
              {PAGE_SIZES.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            <Button onClick={pagination.incrementPage} disabled={isFetching || pagination.isLastPage} id='next_page'>
              Next
            </Button>
          </div>
        </div>
      )}
      <BotEdit
        botsType={botsType}
        botPopup={botPopup}
        botsQueryKey={botsQueryKey}
        botGraphApi={{
          graph,
          openGraph,
          closeGraph,
        }}
        editedBot={editedBot}
        botArchiveApi={botArchiveApi}
        botStatusApi={botStatusApi}
      />
    </Paper>
  )
}
