import { useState } from 'react'
import ReservesTable from './components/ReservesTable/ReservesTable'
import BlocksTable from './components/BlocksTable/BlocksTable'
import { DailyProfitTable, DailyProfitToggle } from './components/DailyProfit/DailyProfit'
import DetailedBookkeepingTable from 'pages/Bookkeeping/components/DetailedBookkeepingTable'
import { BotTable, UNARCHIVED } from 'pages/Bots/BotTable'
import { usePagination, useBotApi } from 'pages/Bots/hooks'
import cx from 'classnames'
import s from './styles.module.css'
import { TablesContainer, DailyProfitContainer } from './styles'
import AnnouncementBlock from "./components/Announcements";

export const Dashboard = () => {
  const [isDailyProfit, setIsDailyProfit] = useState(window.innerWidth > 1920)
  const { page, incrementPage, decrementPage, setStartPage, pageSize, changePageSize, tableRef } = usePagination({
    name: 'main-bot-table',
  })
  const { bots, isFetching, botsQuery, botsQueryKey, setIsFetching, filterBots, exchanges, isLastPage } = useBotApi(
    UNARCHIVED,
    page,
    pageSize
  )

  return (
    <>
      <div className={cx('flex-row')}>
        <div className={cx('column', s.dashboardContainer)}>
          <TablesContainer>
            <div>
              <BlocksTable />
              <ReservesTable />
            </div>
            {isDailyProfit && <DailyProfitContainer>
              <DailyProfitTable />
            </DailyProfitContainer>}
          </TablesContainer>
          <AnnouncementBlock/>
          <BotTable
            tableRef={tableRef}
            botsType={UNARCHIVED}
            data={bots}
            isFetching={isFetching}
            exchanges={exchanges}
            setIsFetching={setIsFetching}
            filterBots={filterBots}
            botsQuery={botsQuery}
            botsQueryKey={botsQueryKey}
            pagination={{
              page,
              incrementPage,
              decrementPage,
              setStartPage,
              pageSize,
              changePageSize,
              tableRef,
              isLastPage,
            }}
          />
          <DetailedBookkeepingTable />
          <DailyProfitToggle active={isDailyProfit} onClick={() => setIsDailyProfit(prev => !prev)} />
        </div>
      </div>
    </>
  )
}
