import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { fetch } from './'
import moment from 'moment'
import { queryClient } from 'App'
import { globalFiltersQuerySelector } from "../redux/selectors/globalFilters";
import { useSelector } from "react-redux";
import { processBotList } from "../pages/Bots/hooks";
import { useMemo, useState } from "react";
import { useExchangesSelector } from "../redux/selectors/settings";
import { useOpportunities } from "./opportunities-api";
import { useOrders } from "./orders-api";
import { useBotErrorsCount } from "./errors-api";

const botAPI = {
  getBotList: (page, pageSize, globalFilters = {}) => {
    return fetch
      .get(`bot`, { searchParams: { page, pageSize, ...globalFilters } })
      .json()
      .catch((err) => console.log(err))
  },
  getBot: (botId) => {
    return fetch
      .get(`bot/${botId}`)
      .json()
      .catch((err) => console.log(err))
  },
  createBot: (botType, botData) => {
    return fetch.post(`bot/${botType}`, { json: botData })
  },
  updateBot: (id, botType, botData) => {
    return fetch.post(`bot/${botType}/${id}`, { json: botData })
  },
  updateBotNotes: (id, notes) => {
    return fetch.put(`notes/${id}`, { json: { notes } })
  },
  disableBots: (ids) => {
    return fetch
      .post(`bots-status/disable`, { json: ids.map(id => ({ bot_id: id })) })
  },
  enableBots: (ids) => {
    return fetch
      .post(`bots-status/enable`, { json: ids.map(id => ({ bot_id: id })) })
  },
  disableBot: (id) => {
    return fetch
      .post(`bot-status/${id}/disable`)
  },
  enableBot: (id) => {
    return fetch
      .post(`bot-status/${id}/enable`)
  },
  changeBotStatusByExchange: (exchange, status) => {
    return fetch
      .post(`bots-status`, { json: { exchange, status: status ? 'true' : 'false' } })
  },
  getArchivedBotList: (page, pageSize, globalFilters = {}) => {
    return fetch
      .get(`archived-bots`, { searchParams: { page, pageSize, ...globalFilters } })
      .json()
      .catch((err) => console.log(err))
  },
  archiveBot: (id) => {
    return fetch
      .post(`archive-bot/${id}`)
      .catch((err) => console.log(err))
  },
  archiveBots: (ids) => {
    return fetch
      .post(`archive-bots`, { json: ids.map(id => ({ bot_id: id })) })
      .catch((err) => console.log(err))
  },
  unarchiveBot: (id) => {
    return fetch
      .post(`archive-bot`, { json: { id, status: 0 } })
      .json()
      .catch((err) => console.log(err))
  },
  getBotHistory: (id, page = 1) => {
    return fetch
      .get(`bot-history/${id}?page=${page}`)
      .json()
      .catch((err) => console.log(err))
  },
  getSignals: (page) => {
    return fetch
      .get(`bot-signals?page=${page}`)
      .json()
      .catch((err) => console.log(err))
  },
  getOpportunities: (page, pageSize, filters) => {
    return fetch
      .get(`bot-opportunities`, { searchParams: { page, pageSize, ...filters } })
      .json()
      .catch((err) => console.log(err))
  },
  getHistory: (page, pageSize, sortBy, filter, filters) => {
    const searchParams = {
      page,
      pageSize,
      ...filters,
    }

    if (sortBy?.id) {
      searchParams.sortedField = sortBy.id
      searchParams.sortedDirection = sortBy.desc ? 'desc' : 'asc'
    }

    if (filter?.id) {
      searchParams.filteredField = filter.id
      searchParams.filteredValue = filter.value
    }

    return fetch
      .get(`filtered-history`, { searchParams })
      .json()
      .catch((err) => console.log(err))
  },
  getChartData: (botId, startTime, endTime) => {
    return fetch
      .get(`chart`, {
        searchParams: {
          bot_id: botId,
          start_time: startTime,
          end_time: endTime,
        },
      })
      .json()
      .catch((err) => console.log(err))
  },
  getAnnouncements: () => {
    return fetch
      .get(`delisting-announcements`, {})
      .json()
      .catch((err) => console.log(err))
  },
}

export default botAPI

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'

const formatClassicBot = bot => ({
  ...bot,
  botId: `${bot.id}-${bot.external_id.toLowerCase()}`,
  status: bot.status === 'true',
  dex_symbol_left: bot.classic_arbitrage[0]?.dex.left,
  dex_symbol_right: bot.classic_arbitrage[0]?.dex.right,
  cex_symbol_left: bot.classic_arbitrage[0]?.cex.left,
  cex_symbol_right: bot.classic_arbitrage[0]?.cex.right,
  exchange_from: {
    name: bot.classic_arbitrage[0]?.dex.exchange,
    network: bot.classic_arbitrage[0]?.dex.network,
  },
  exchange_to: {
    name: bot.classic_arbitrage[0]?.cex.exchange,
  },
  direction: bot.classic_arbitrage.map((item) => ({ side: item.side })),
  profit: bot.classic_arbitrage.map((item) => ({ profit: item.profit })),
  qn: bot.classic_arbitrage.map((item) => ({ amount: item.amount })),
  bot_error: bot.bot_error && {
    error: bot.bot_error.error,
    timestamp: bot.bot_error.timestamp,
    moment: moment(bot.bot_error.timestamp),
    momentUnix: moment(bot.bot_error.timestamp).unix(),
  },
  leveling_rules: bot.leveling_rule,
})

const formatTripleBot = bot => ({
  ...bot,
  botId: `${bot.id}-${bot.external_id.toLowerCase()}`,
  status: bot.status === 'true',
  direction: bot.triple_arbitrage.map((item) => ({ side: item.side })),
  profit: bot.triple_arbitrage.map((item) => ({ profit: item.profit })),
  qn: bot.triple_arbitrage.map((item) => ({ amount: item.amount })),
  bot_error: bot.bot_error && {
    error: bot.bot_error.error,
    timestamp: bot.bot_error.timestamp,
    moment: moment(bot.bot_error.timestamp),
    momentUnix: moment(bot.bot_error.timestamp).unix(),
  },
  leveling_rules: bot.leveling_rule,
})

const formatBotsResponse = (data) => {
  const { classic_bots, triple_bots } = data || {}

  let res = {}

  if (classic_bots) {
    for (const bot of classic_bots) {
      const formatted = formatClassicBot(bot)

      res[formatted.botId] = formatted
    }
  }

  if (triple_bots) {
    for (const bot of triple_bots) {
      const formatted = formatTripleBot(bot)

      res[formatted.botId] = formatted
    }
  }

  return res
}

export const useBotList = ({ botType, pagination, globalFilters }) => {
  const queryKey = ['bot-list', botType, pagination.page, pagination.pageSize, globalFilters]
  const query = useQuery({
    queryKey,
    queryFn: async ({ queryKey }) => {
      const [, type, page, pageSize, globalFilters] = queryKey
      const call = type === UNARCHIVED ? botAPI.getBotList : botAPI.getArchivedBotList
      const response = await call(page, pageSize, globalFilters)
      const bots = formatBotsResponse(response)

      return {
        bots,
        pagination,
        isLastPage: Object.keys(bots).length < pageSize,
      }
    },
    keepPreviousData,
  })

  return [query, queryKey]
}

export const useBotById = ({ botId, pagination  }) => {
  const globalFilters = useSelector(globalFiltersQuerySelector);
  const queryKey = ['bot-list', botId, pagination.page, pagination.pageSize, globalFilters]
  const query = useQuery({
    queryKey,
    queryFn: async ({ queryKey }) => {
      const call = botAPI.getBot
      const response = await call(botId, globalFilters)
      const bots = formatBotsResponse(response)

      return {
        bots,
        pagination,

      }
    },
    keepPreviousData,
  })

  const { data, isFetching } = query;

  const bots = Object.values(data?.bots || {})

  const exchangesList = useExchangesSelector()
  const opportunitiesQuery = useOpportunities()
  const ordersQuery = useOrders()
  const botErrors = useBotErrorsCount()

  const opportunities = opportunitiesQuery.data
  const orders = ordersQuery.data

  const [filter, filterBots] = useState(null) // { field, value } { "field" : "id", "value" : 6254 }

  const { filteredBots, exchanges } = useMemo(() => {
    return processBotList(bots, { filter, exchangesList, botErrors, opportunities, orders })
  }, [bots, exchangesList]);

  return {
    bots: filteredBots,
    isFetching,
    botsQuery:query,
    filterBots,
    exchanges,
    botsQueryKey: queryKey,
  }
};

export const mutateBotStatus = (botId, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    return {
      ...prev,
      bots: {
        ...prev.bots,
        [botId]: {
          ...prev.bots[botId],
          status: !prev.bots[botId].status,
        },
      },
    }
  })
}

export const switchArchiveBotStatus = (botId, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    if (!Array.isArray(botId)) {
      const { [botId]: _, ...rest } = prev.bots

      return {
        ...prev,
        bots: rest,
      }
    } else {
      return {
        ...prev,
        bots: {
          ...Object.fromEntries(
            Object.entries(prev.bots).filter(([_id, bot]) => !botId.includes(bot.id))
          ),
        },
      }
    }
  })
}

export const mutateAllStatuses = (newStatus, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    return {
      ...prev,
      bots: {
        ...Object.fromEntries(
          Object.entries(prev.bots).map(([id, bot]) => [id, { ...bot, status: newStatus }])
        ),
      },
    }
  })
}

export const toggleBotRule = (botId, ruleId, newStatus, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    const bot = { ...prev.bots[botId] }

    bot.leveling_rules = bot.leveling_rules.map(rule => {
      // eslint-disable-next-line eqeqeq
      if (rule.id == ruleId) {
        return { ...rule, enabled: newStatus }
      }

      return rule
    })

    return {
      ...prev,
      bots: {
        ...prev.bots,
        [botId]: bot,
      },
    }
  })
}

export const updateBotRule = (botId, newRule, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    const bot = { ...prev.bots[botId] }

    bot.leveling_rules = bot.leveling_rules.map(rule => {
      // eslint-disable-next-line eqeqeq
      if (rule.id == newRule.id) {
        return { ...rule, ...newRule }
      }

      return rule
    })

    return {
      ...prev,
      bots: {
        ...prev.bots,
        [botId]: bot,
      },
    }
  })
}

export const updateBotNotes = (botId, notes, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    const bot = { ...prev.bots[botId] }

    bot.notes = notes

    return {
      ...prev,
      bots: {
        ...prev.bots,
        [botId]: bot,
      },
    }
  })
}

export const mutateBot = (bot, prevBotId, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    const updated = bot.type === 'CLASSIC' ? formatClassicBot(bot) : formatTripleBot(bot)

    if (prevBotId !== updated.botId) {
      const { [prevBotId]: _, ...rest } = prev.bots

      return {
        ...prev,
        bots: {
          ...rest,
          [updated.botId]: updated,
        },
      }
    }

    return {
      ...prev,
      bots: {
        ...prev.bots,
        [updated.botId]: updated,
      },
    }
  })
}

export const mutateStatusesByIds = (ids, newStatus, queryKey) => {
  queryClient.setQueryData(queryKey, prev => {
    return {
      ...prev,
      bots: {
        ...Object.fromEntries(
          Object.entries(prev.bots).map(([id, bot]) => [id, { ...bot, status: ids.includes(bot.botId) ? newStatus : bot.status }])
        ),
      },
    }
  })
}

export const useBotData = (botId, queryKey) => {
  const query = useQuery({ queryKey })

  return query.data?.bots?.[botId]
}
